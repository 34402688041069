button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.search-form-container {
    animation: fadein .5s;
    max-width: 1140px;
    position: relative;
}

.form-group {
    position: relative;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    background-image: none;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    overflow: hidden;
}

.selectize-control.form-control {
    padding: 0;
}

.selectize-control.form-control .selectize-input {
    padding-top: 4px;
    padding-right: 12px;
    padding-bottom: 8px;
    min-height: 40px;
    border-radius: 0;
}

.tag-input-group .selectize-input,
.tag-input-group .selectize-input .selectize-input,
.tag-input-group .selectize-input .selectize-input div,
.tag-input-group .selectize-input div,
.tag-input-group .selectize-input li,
.tag-input-group li {
    margin: 4px 4px 0 0;
    padding: 2px 3px;
    float: left;
    width: 100%;
}

.search-form-container .tag-input-group {
    height: auto;
    min-height: 50px;
}

.selectize-input {
    min-height: 32px;
    display: block;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #58595b;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.selectize-control.form-control .selectize-input>input {
    margin-top: 3px !important;
    padding: 3px 0 !important;
    float: left;
}

.search-form-container .selectize-control .selectize-input>input {
    font-size: 14px;
    font-size: .875rem;
    margin-top: 6px !important;
}

.tag-input-group .selectize-input input,
.tag-input-group input {
    border: none;
    width: 100%;
}

input[placeholder] {
    text-overflow: ellipsis;
}

.selectize-input > * {
    vertical-align: baseline;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
}

.selectize-control .selectize-dropdown.form-control,
.tag-input-wrapper .selectize-dropdown.form-control {
    border-width: 0;
    padding: 0;
    overflow: visible;
    line-height: 1.42857143;
    background-color: transparent;
    background-image: none;
    border: none;
    border-radius: 4px;
    box-shadow: none;
}

.search-form-container .search-input-group .help-block {
    margin-bottom: 0;
    padding: 0 4px;
    position: relative;
}

.has-error-first .help-block,
.has-error-second .help-block,
.has-error .help-block {
    color: #e3224a;
}

.search-input-group label.sr-only~.label-icon {
    font-size: 22px;
    font-size: 1.375rem;
    top: 12px;
    left: 12px;
}
.btn-reset {
    background: transparent;
    border-radius: none;
    border: none;
    padding: 0;
    margin: 0;
    min-height: auto;
    display: inline-block;
}

.form-control-feedback:before,
.label-icon {
    position: absolute;
    font-size: 16px;
    font-size: 1rem;
    z-index: 10;
}

.label-icon {
    top: 2rem;
    left: 10px;
    color: #bebebe;
}

.hidden {
    display: none !important;
}

button {
    overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

.selectize-input>input {
    display: inline-block !important;
    padding: 0 !important;
    min-height: 0 !important;
    max-height: none !important;
    max-width: 100% !important;
    margin: 0 2px 0 0 !important;
    text-indent: 0 !important;
    border: 0 none !important;
    background: none !important;
    line-height: inherit !important;
    -webkit-user-select: auto !important;
    box-shadow: none !important;
}

.selectize-input input:focus {
    outline: none;
}

.tag-input-group.focus:not(.full),
.tag-input-group .selectize-input.focus:not(.full) {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 5px;
    outline-offset: -2px;
}

.search-form-container .selectize-control .selectize-input {
    min-height: 48px;
    padding-left: 45px !important;
}

.search-form-container .selectize-input .tag {
    margin-top: 6px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tag-input-group .selectize-input .tag,
.tag-input-group .tag {
    border: none;
    width: auto;
    position: relative;
    color: #fff;
    min-height: auto;
    padding: 0 !important;
}

.selectize-input>* {
    vertical-align: baseline;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
}

.selectize-input .items:not(.tag-as-text) .tag .content,
.selectize-input .items:not(.tag-as-text) .tag .delete,
.selectize-input .items:not(.tag-as-text) .tag .remove,
.tag-input-group .items:not(.tag-as-text) .tag .content,
.tag-input-group .items:not(.tag-as-text) .tag .delete,
.tag-input-group .items:not(.tag-as-text) .tag .remove {
    background: var(--ft--color-primary);
}

.selectize-input .items:not(.tag-as-text) .tag .content.withRemoveBtn,
.tag-input-group .items:not(.tag-as-text) .tag .content.withRemoveBtn {
    padding-right: 25px;
}

.search-form-container .selectize-input .tag .content {
    min-height: 1.8rem;
    padding: 5px 2px 3px 8px;
    word-break: break-word;
}

.selectize-control.single .tag .content {
    padding-right: 7px;
}

.search-input-group .with-location-area .form-control .content {
    height: auto;
    overflow: auto;
    white-space: normal;
}

.tag-input-group .selectize-input .tag .content,
.tag-input-group .tag .content {
    border-radius: 3px;
    padding-right: 23px;
    height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.tag-input-group .selectize-input .tag .content,
.tag-input-group .selectize-input .tag .delete,
.tag-input-group .selectize-input .tag .remove,
.tag-input-group .tag .content,
.tag-input-group .tag .delete,
.tag-input-group .tag .remove {
    display: inline-block;
    padding: 3px 2px 3px 7px;
    float: left;
}

.selectize-input .items:not(.tag-as-text) .tag .delete,
.selectize-input .items:not(.tag-as-text) .tag .remove,
.tag-input-group .items:not(.tag-as-text) .tag .delete,
.tag-input-group .items:not(.tag-as-text) .tag .remove {
    border-left: 1px solid var(--ft--color-primary-60);
}

.selectize-input .items:not(.tag-as-text) .tag .content,
.selectize-input .items:not(.tag-as-text) .tag .delete,
.selectize-input .items:not(.tag-as-text) .tag .remove,
.tag-input-group .items:not(.tag-as-text) .tag .content,
.tag-input-group .items:not(.tag-as-text) .tag .delete,
.tag-input-group .items:not(.tag-as-text) .tag .remove {
    background: var(--ft--color-primary);
}

.tag-input-group .selectize-input .tag .delete,
.tag-input-group .selectize-input .tag .remove,
.tag-input-group .tag .delete,
.tag-input-group .tag .remove {
    border-radius: 0 3px 3px 0;
    padding-right: 6px;
    padding-left: 5px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    line-height: 16px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    border-width: 0 0 0 1px;
}

.tag-input-group .selectize-input .tag .remove,
.tag-input-group .tag .remove {
    color: #fff;
}

.tag-input-group .selectize-input .tag .content,
.tag-input-group .selectize-input .tag .delete,
.tag-input-group .selectize-input .tag .remove,
.tag-input-group .tag .content,
.tag-input-group .tag .delete,
.tag-input-group .tag .remove {
    display: inline-block;
    padding: 3px 2px 3px 7px;
    float: left;
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
}

button,
select {
    text-transform: none;
}

button {
    overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

.form-control .selectize-input {
    border: 0;
}

.form-control .selectize-input,
.selectize-control.form-control {
    margin: 0;
    padding: 0;
}

.selectize-control .form-control,
.selectize-control .selectize-input,
.tag-input-wrapper .form-control,
.tag-input-wrapper .selectize-input {
    padding-bottom: 7px;
    height: auto !important;
}

.selectize-control .selectize-dropdown,
.tag-input-wrapper .selectize-dropdown {
    top: 50px;
}

.selectize-control .selectize-dropdown,
.selectize-control div.t-autocomplete-menu,
.tag-input-wrapper .selectize-dropdown,
.tag-input-wrapper div.t-autocomplete-menu {
    position: absolute;
    width: 100%;
    left: -1px;
}

.tag-input-group,
.tag-input-group .selectize-input {
    list-style: none;
    padding-top: 4px;
    margin-bottom: 0;
    min-height: 42px;
}

.selectize-dropdown,
div.t-autocomplete-menu {
    z-index: 1021;
}

.selectize-dropdown .selectize-dropdown-content,
div.t-autocomplete-menu .selectize-dropdown-content {
    padding-top: 8px;
    padding-bottom: 8px;
}

.selectize-dropdown .search-in-progress,
.selectize-dropdown .selectize-dropdown-content,
.selectize-dropdown ul,
div.t-autocomplete-menu .search-in-progress,
div.t-autocomplete-menu .selectize-dropdown-content,
div.t-autocomplete-menu ul {
    border: 1px solid rgba(0, 0, 0, .05);
    box-shadow: 0 3px 12px rgba(0, 0, 0, .175);
    padding: 7px 0;
    background-color: #fff;
    list-style-type: none;
    max-height: 300px;
    overflow: auto;
    padding-top: 3px;
    margin-bottom: 0;
}

.selectize-dropdown .optgroup,
.selectize-dropdown .optgroup>ul,
div.t-autocomplete-menu .optgroup,
div.t-autocomplete-menu .optgroup>ul {
    border: none !important;
    background: none !important;
    padding: 0 !important;
}

.selectize-dropdown .optgroup,
div.t-autocomplete-menu .optgroup {
    margin: 10px 0 !important;
}

.selectize-dropdown .optgroup-block,
div.t-autocomplete-menu .optgroup-block {
    padding: 0;
    box-shadow: none;
    max-height: none;
}

.selectize-dropdown .option,
.selectize-dropdown [data-no-results],
.selectize-dropdown [data-selectable],
.selectize-dropdown li,
div.t-autocomplete-menu .option,
div.t-autocomplete-menu [data-no-results],
div.t-autocomplete-menu [data-selectable],
div.t-autocomplete-menu li {
    font-size: 12px;
    font-size: .75rem;
    color: #58595b;
    padding: 10px;
    border-bottom: 1px solid #edefee;
    background: #fff;
    margin: 0 10px;
    width: 100%;
    width: calc(100% - 20px);
    cursor: pointer;
    position: relative;
    display: block;
}

.selectize-dropdown .option,
.selectize-dropdown [data-selectable],
div.t-autocomplete-menu .option,
div.t-autocomplete-menu [data-selectable] {
    width: auto;
    float: none;
}

.selectize-dropdown [data-selectable].active,
.selectize-dropdown [data-selectable].active .highlight,
.selectize-dropdown [data-selectable].highlight,
.selectize-dropdown [data-selectable].selected,
.selectize-dropdown [data-selectable].selected.highlight,
.selectize-dropdown [data-selectable].selected .selection,
.selectize-dropdown [data-selectable]:hover,
.selectize-dropdown [data-selectable]:hover .highlight,
.selectize-dropdown [data-selectable]:hover .selection,
div.t-autocomplete-menu li.active,
div.t-autocomplete-menu li.active .highlight,
div.t-autocomplete-menu li.highlight,
div.t-autocomplete-menu li.selected,
div.t-autocomplete-menu li.selected.highlight,
div.t-autocomplete-menu li.selected .selection,
div.t-autocomplete-menu li:hover,
div.t-autocomplete-menu li:hover .highlight,
div.t-autocomplete-menu li:hover .selection {
    color: #fff;
    background: var(--ft--color-primary);
}

.selectize-dropdown-content .option button {
    text-align: left;
}

@media screen and (max-width: 768px) {
    .selectize-control.with-location-area.job {
        border-radius: 0.25rem 0 0 0 !important;
    }

    .selectize-control.with-location-area.lieu {
        border-radius: 0 0 0 0.25rem !important;
    }
}

.search-form-container .max-keywords {
    left: 6px;
}

.max-keywords {
    position: absolute;
    top: 72px;
    z-index: 1000;
    right: 60px;
    left: 0;
    max-width: 400px;
}

.search-form p {
    color: #808285;
}

.max-keyword-notice {
    margin: 0;
    padding: 7px;
    background: rgba(62, 62, 62, .8);
    color: #fff !important;
    font-weight: 400;
}
.max-keyword-notice:before {
    content: "";
    width: 0;
    height: 0;
    vertical-align: middle;
    border-top: 0;
    border-bottom: 6px solid rgba(62, 62, 62, .8);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    position: absolute;
    top: -6px;
    left: 13px;
}

.selectize-input button.remove {
    padding-right: 6px !important;
}

.selectize-dropdown .active .highlight,
div.t-autocomplete-menu .active .highlight {
    text-decoration: underline;
}

.selectize-dropdown .selection,
.selectize-dropdown [data-selectable] .highlight,
div.t-autocomplete-menu .selection,
div.t-autocomplete-menu [data-selectable] .highlight {
    font-weight: 700;
    color: #535353;
}

@media screen and (max-width: 768px) {
    .selectize-no-results.tag-input-group.selectize-dropdown.form-control.selectize-with-icon {
        top: 50px !important;
    }

    .max-keywords {
        top: 50px !important;
    }
}