@font-face {
    font-family: 'icons';
    font-display: block;
    src: url("../fonts/icons.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    /* font-display: swap; */
}

/* UTILS */
.home-bg-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../images/home-bg.jpg');
    background-image: url('../images/home-bg.webp');
    background-image: url('../images/home-bg.avif');
    background-position-x: '80%';
}

.bg-gradient {
    background: linear-gradient(90deg, rgba(65, 64, 66, 0.3) 0%, rgba(0, 0, 0, 0.5) 19.27%, rgba(0, 0, 0, 0.5) 53.65%, rgba(65, 64, 66, 0) 93.23%);
}

.bg-opacity-25 {
    background-color: rgba(255,224,223, 0.25) !important;
}

.bg-light-primary {
    background-color: var(--ft--color-primary-05);
}

.bg-primary-10 {
    background-color: var(--ft--color-primary-10);
}

.bg-primary-20 {
    background-color: var(--ft--color-primary-20);
}

.bg-filters {
    background-color: #F6F7FB;
}

.bg-none {
    background: none;
}

.text-underline {
    text-decoration: underline;
}

.border-1 {
    border: 1px solid;
}

.border-light-grey {
    border-color: #E6E7E8;
}

.border-grey {
    border-color: #A7A9AC;
}

.box-shadow-grey {
    box-shadow: 0 0 0 0.0625rem #A7A9AC inset;
}

.box-shadow-none {
    box-shadow: none;
}

.rounded-xl {
    border-radius: 20px;
}

.display-grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 768px) {
    .display-grid-2 {
        grid-template-columns: 1fr;
    }
}

.vw-80 {
    width: 80vw;
}

.gap-30 {
    gap: 30px
}

.divider {
    border: solid 1px #eeeeee;
    width: 90%;
}

/* todo : utile ? */
.text-gray-90 {
    color: #414042 !important;
}

.box-shadow {
    box-shadow: 0px 2px 8px 0px #0000001A;
    padding: 20px;
}

.overflow-hidden {
    overflow: hidden;
}

.vertical-line-tall {
    width: 3px;
    height: 40px;
    align-self: center;
    margin: 0 auto;
    border-radius: 1.5px;
    opacity: 30%;
}

.line-splitter {
    width: 3px;
    height: 40px;
    align-self: center;
    margin: 0 auto;
    border-radius: 1.5px;
}
@media screen and (max-width: 768px) {
    .line-splitter {
        height: 3px;
        width: 100%;
    }
}

.flex-1 {
    flex:1;
}

.flex-1-3 {
    flex: 0 0 33.333333%;
}

.w-fit-content {
    width: fit-content;
}

@media screen and (min-width: 768px) {
    .w-md-fit-content {
        width: fit-content !important;
    }
}

.rounded-2 {
    border-radius: 0.5rem !important;
}

.top-0 {
    top: 0px;
}

.right-0 {
    right: 0px;
}

.bottom-0 {
    bottom: 0px;
}

.left-0 {
    left: 0px;
}

.fit-content {
    width: fit-content;
}

.overflow-y-scroll {
    overflow-y: scroll;
}
.overflow-x-scroll {
    overflow-x: scroll;
}

/* TAG */
.tag-green {
    background: #DEF2F0;
    color: #310300;
}

.tag-secondary {
    background: #ffffff;
    color: #414042;
    border: solid 1px #939598;
}

.tag-light-blue {
    background: #DEF0FA;
    color: #07475A;
}

.tag-light-blue-marguerite {
    background: #DCE1F5;
    color: #1C2B69;
}

/* ASYMETRICS CONTAINERS */
.container-lg-ml {
    width: 100%;
    
}

@media (max-width: 768px) {
    .container-lg-ml {
        padding: 0 15px;
        margin: auto;
    }
}

@media (min-width: 992px) {
    .container-lg-ml {
        max-width: calc(960px + (100vw - 960px)/2);
        margin-left: auto;
    }
}

@media (min-width: 1200px) {
    .container-lg-ml {
        max-width: calc(1140px + (100vw - 1140px)/2);
        margin-left: auto;
    }
}

/* text & paragraphe */
.text-helvetica-12 {
    font-family: var(--font-family-helvetica);
    font-size: 12px;
}

.text-helvetica-13 {
    font-family: var(--font-family-helvetica);
    font-size: 13px;
}

.text-helvetica-14 {
    font-family: var(--font-family-helvetica);
    font-size: 14px;
}

.text-helvetica-20 {
    font-family: var(--font-family-helvetica);
    font-size: 20px;
}

.text-roboto-12 {
    font-size: 12px;
    font-family: var(--font-family-roboto);
}

.text-roboto-13 {
    font-size: 13px;
    font-family: var(--font-family-roboto);
}

.text-roboto-14 {
    font-size: 14px;
    font-family: var(--font-family-roboto);
}

.text-roboto-16 {
    font-size: 16px;
    font-family: var(--font-family-roboto);
}

.text-roboto-18 {
    font-size: 18px;
    font-family: var(--font-family-roboto);
}

.text-roboto-20 {
    font-size: 20px;
    font-family: var(--font-family-roboto);
}

.text-roboto-24 {
    font-size: 24px;
    font-family: var(--font-family-roboto);
}

.text-roboto-28 {
    font-size: 28px;
    font-family: var(--font-family-roboto);
}

.text-roboto-32 {
    font-size: 32px;
    font-family: var(--font-family-roboto);
}

.text-roboto-38 {
    font-size: 38px;
    font-family: var(--font-family-roboto);
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

/* dropdown*/

.dropdown-body-custom {
    padding: 10px;
    max-width: none;
    
}

.dropdown-item-custom {
    white-space: nowrap;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #E6E7E8;
}

.btn-dropdown-option:hover,
.btn-dropdown-option:focus {
    background-color: var(--ft--color-primary) !important;
    /* color: white !important; */
}

/* image */
.picto-48-48 > svg {
    width: 48px!important;
    height: 48px!important;
    color:#d1d3d4;
}

/* FORM */
.input-group-lg > .form-control-wrapper > .form-control:not(textarea),
.input-group-lg > .form-control-wrapper > .custom-select {
    height: 50px;
}

.input-group-lg > .input-group-prepend > .input-group-text {
    height: 50px;
}

.input-focus:focus-within {
    outline: 0;
    box-shadow: 0 0 0 0.125rem #414042 inset;
    color: #495057;
}

.input-focus:focus-within .form-control:focus {
    background: none;
}

.input-focus.is-invalid > .input-group {
    box-shadow: 0 0 0 0.075rem #dc3545 inset;
}

/* CITYHOME COMPONENT */
h2.cityhome-h2 {
    font-size: 24px;
    font-weight: 700;
    color: var(--primary);
}

.cityhome-event-title {
    font-family: var(--font-family-helvetica);
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

/* COMPONENT KEYFIGURE */

.keyfigure-data {
    font-size: 36px;
}

/* COMPONENT cityCloseCities */
.cityCloseCities-panel-cities {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    list-style: none;
}
@media screen and (max-width: 768px) {
    .cityCloseCities-panel-cities {
        grid-template-columns: 1fr;
    }
}

.cityCloseCities-cityItem {
    /* min-width: 25vw; */
    /* min-width: min(300px, 90vw); */
    border-radius: 16px;

    font-size: 13px;
    min-height: 53px;
}
.cityCloseCities-cityItem > a {
    display: grid;
    grid-template-columns: 3fr 1fr;
    padding: 20px;
}
.cityCloseCities-cityItem > a > svg {
    justify-self: end;
    align-self: center;
}

/* COMPONENT close-opportunities  */
.close-opportunities-card-title {
    font-size: 18px;
}

.close-opportunities-card > a {
    font-size: 14px;
}

/* COMPONENT cityhousingsection  */
.cityhousingsection-metric-title {
    font-size: 18px;
}

/* COMPONENT jobCard */
.jobCard-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.jobCard-company-text {
    font-size: 14px;
    line-height: 18px;
}

/* COMPONENT CITYSERVICE */
.cityservice-minify {
    max-height: 255px;
}

/* COMPONENT GUIDEHOME */
.card-tips {
    max-width: 400px;
    min-width: min(450px, 70vw);
}

.card-tips-little {
    min-width: min(230px, 70vw);
}

div.cascade[aria-expanded=false] > .icon{
    transform: rotate(180deg);
}

li.jobItem:hover {
    background: #dcdff380;
    cursor: pointer;
}

/* md media breaks */
@media screen and (min-width: 768px) {
    div.search-button-container {
        height: 50px;
    }
    div.form-group-city-select,
    div.form-group-job-select {
        border-radius: 0.25rem !important;
    }
}

@media screen and (max-width: 768px) {
    div.form-group-city-select {
        border-radius: 0 0 0 0.25rem !important;
    }
    div.form-group-job-select {
        border-radius: 0.25rem 0 0 0 !important;
    }
    .button-city-search {
        border-radius: 0 0.25rem 0.25rem 0 !important;
    }

    .text-sm-white {
        color: white;
    }
}

.welcome-nav-item {
    margin-bottom: 1px;
}
.welcome-nav-item > .nav-link:not(.active) {
    background-color: #414042 !important;
}

/* DEPARTEMENT */
.departement-cities {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

@media screen and (max-width: 768px) {
    .departement-cities {
        grid-template-columns: 1fr;
    }
}

.custom-menu-navigation {
    border-bottom: 1px solid #eee;
    padding-top: 12px;
    border-bottom: 1px solid #d1d3d4;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    margin-bottom: 0;
}

.custom-menu-navigation > li {
    margin-right: 0.25em;
}

.custom-nav-link {
    background-color: initial;
    border: 1px solid #0000;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #f3f3f3 !important;
    border-radius: 6px 6px 0 0;
    color: #55637f;
    font-family: var(---font-family-roboto);
    font-size: 14px;
    font-weight: 400;
    height: 51px;
    padding: 18px 1rem 0 1rem;
    text-align: center;
    white-space: nowrap;
    display: block;
    height: 100%;
    margin-top: 0;
    margin-bottom: -1px;
}

li.custom-nav-item.active > .custom-nav-link {
    background-color: #fff !important;
    border: 1px solid #eee;
    border-bottom: none;
    color: var(--primary) !important;
    font-weight: 700 !important;
    padding-top: 23px !important;
    height: 62px !important;
}

@media screen and (max-width: 768px) {
    li.custom-nav-item.active>.custom-nav-link {
        height: 83px !important;
    }
}

.custom-menu-navigation > :not(li.active) {
    padding-top: 12px;
}

.regions-list {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    min-width: 1000px;
}

.after-separator:not(:last-child)::after{
    content: '•';
    padding-left: 8px;
}

.selectize-input.items.has-options.has-items .tag {
    background-color: var(--ft--color-primary) !important;
}

.tag-input-group .items:not(.tag-as-text) .tag .content {
    background-color: transparent !important;
    padding-top: 5px;
}

.tag-input-group .items:not(.tag-as-text) .tag .remove {
    background: rgba(0,0,0,0.15) !important;
}

.high-contrast .tag-input-group .items:not(.tag-as-text) .tag .remove {
    border-left: 1px solid white !important;
}

.high-contrast .selectize-dropdown-content .btn-reset:not(.btn-link,.close) {
    border: 0 !important;
}

.high-contrast .selectize-dropdown [data-selectable].active {
    background-color: var(--ft--color-primary) !important;
    color: #fff !important;
}
.high-contrast .selectize-dropdown [data-selectable].active .highlight {
    background-color: transparent !important;
}
.high-contrast .btn-reset:not(.btn-link,.close).max-keywords-button {
    border: 0 !important;
}

.selectize-input.items.has-options.full.has-items {
    max-height: 48px;
}

.high-contrast .picto-48-48 > svg {
    color: inherit;
}

@media screen and (max-width: 768px) {
    .selectize-no-results.tag-input-group.selectize-dropdown.form-control.selectize-with-icon {
        top: 48px !important;
    }
}